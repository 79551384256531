import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import UnifiedSelect from "../components/UnifiedSelect";

const PartPricing = ({ isManager }) => {
  const [searchString, setSearchString] = useState("");
  const [tier, setTier] = useState("");
  const location = useLocation();
  
  const pre = (c) => "part-pricing__" + c;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const search = params.get("make");
    const tier = params.get("tier");

    if (search) {
      setSearchString(search);
    }
    if (tier) {
      setTier(tier);
    }
  }, [location.search]);

  return (
    <div className={pre("container")}>
      <div className={pre("pageHeader")}>
        <h1>
          Special Order Pricing Calculator
          {/* <span>
            <Link to={`/old${location.search}`}>revert to old UI</Link>
          </span> */}
        </h1>
        <UnifiedSelect
          search={searchString}
          tier={tier}
          isManager={isManager}
        />
      </div>
    </div>
  );
};
export default PartPricing;
