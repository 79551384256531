// ReviewMarkupsBase.js
import React from "react";
import { Link } from "react-router-dom";
import "../styles/ReviewMarkups.css";
import ReturnPrevious from "./ReturnPrevious";

const pre = (c) => "review-markups__" + c;

const ReviewMarkupsLink = ({ to, label }) => {
  if (!label) label = "Review Markups";
  return (
    <Link className="review-markups-link" to={to}>
      {label}
    </Link>
  );
};

// Alert Message Component
const AlertMessage = () => (
  <div className="alert">
    <strong>ATTENTION:</strong>&ensp;Case Parts internal use only!
  </div>
);

// Header Row Component
const HeaderRow = ({ columns }) => (
  <div className={pre("header")}>
    {columns.map((col, idx) => (
      <div key={idx}>{col}</div>
    ))}
  </div>
);

// Checkbox Component
const Checkbox = ({ label, checked, onChange }) => (
  <div>
    <label>
      <input type="checkbox" checked={checked} onChange={onChange} />
      {label}
    </label>
  </div>
);

const ReviewMarkupsBase = ({
  title,
  isPartstown,
  returnUrl,
  data,
  columns,
  renderVendorRow,
  checkboxes,
  containerClass,
  onContainerDoubleClick,
}) => {
  // Check if any checkbox label starts with "Show variance"
  const hasShowVariance = checkboxes.some(
    (checkbox) =>
      checkbox.label.startsWith(" Show variance") && checkbox.checked
  );
  // Check if any checkbox label starts with "Show variance"
  const hasShowPricing = checkboxes.some(
    (checkbox) => checkbox.label.startsWith(" Show pricing") && checkbox.checked
  );

  return (
    <div className={pre("container")}>
      <AlertMessage />
      <h1>
        {title}
        <ReturnPrevious url={returnUrl} />
        {!isPartstown && <ReviewMarkupsLink to="/export" label="Export" />}
        <ReviewMarkupsLink
          to={"/pricing" + (isPartstown ? "/?pt=true" : "")}
          label="Pricing Table"
        />
      </h1>

      {/* Control Checkboxes */}
      {checkboxes.map((checkbox, idx) => (
        <Checkbox key={idx} {...checkbox} />
      ))}

      {/* Show variance guidelines if needed */}
      {hasShowVariance && (
        <div style={{ textAlign: "center" }}>
          <div>
            <span className="review-markups__variance-positive">
              {hasShowPricing ? "Price increase" : "Markup increase"}
            </span>
            <div>
              <span className="review-markups__variance-negative">
                {hasShowPricing ? "Price decrease" : "Markup decrease"}
              </span>
            </div>
          </div>
        </div>
      )}

      <div
        className={pre(`container ${containerClass}`)}
        onDoubleClick={onContainerDoubleClick}
      >
        <HeaderRow columns={columns} />
        {data.map(renderVendorRow)}
      </div>
    </div>
  );
};

export default ReviewMarkupsBase;
