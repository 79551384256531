const partstownMarkups = [
  {
    discount: 0,
    endUserMarkup: 1.35,
    dealerMarkup: 1.25,
    wholesalerMarkup: 1.2,
  },
  {
    discount: 0.19,
    endUserMarkup: 1.35,
    dealerMarkup: 1.25,
    wholesalerMarkup: 1.225,
  },
  {
    discount: 0.22,
    endUserMarkup: 1.35,
    dealerMarkup: 1.275,
    wholesalerMarkup: 1.25,
  },
  {
    discount: 0.27,
    endUserMarkup: 1 / (1 - 0.27),
    dealerMarkup: 1.3,
    wholesalerMarkup: 1.25,
  },
  {
    discount: 0.3,
    endUserMarkup: 1 / (1 - 0.3),
    dealerMarkup: 1.35,
    wholesalerMarkup: 1.25,
  },
  {
    discount: 0.37,
    endUserMarkup: 1 / (1 - 0.37),
    dealerMarkup: 1.375,
    wholesalerMarkup: 1.3,
  },
  {
    discount: 0.5,
    endUserMarkup: 2,
    dealerMarkup: 1.4,
    wholesalerMarkup: 1.3,
  },
  {
    discount: 0.8,
    endUserMarkup: 5,
    dealerMarkup: 2,
    wholesalerMarkup: 1.65,
  },
  {
    discount: 1,
    endUserMarkup: 5,
    dealerMarkup: 2,
    wholesalerMarkup: 1.75,
  },
];

export default partstownMarkups;
