/*
    Every element should have a Vendor (string) and Discount (0 <= x < 1.0) property
    but some elements might have additional optional parameters:
    
    MinOrder: a string listing the minimum order amount and any other restrictions
    PromptForListPrice: CSRs normally enter Cost unless this boolean is true
    EndUserMarkup,
    DealerMarkup,
    WholesalerMarkup: Markups are usually calculated dynamically, but when present,
                      these override markups are used instead
 */
const vendors = [
  {
    Vendor: "ADMIRAL CRAFT",
    Discount: 0.5,
  },
  {
    Vendor: "ANTHONY",
    Discount: 0.5,
  },
  {
    Vendor: "ARDCO",
    Discount: 0.5,
  },
  {
    Vendor: "ARCTIC AIR / COAST APPLIANCE",
    Discount: 0.2841,
  },
  {
    Vendor: "ARCTIC AIR / BROICH",
    Discount: 1 / 3,
  },
  {
    Vendor: "ARCTIC AIR / ENCOMPASS AKA DAYTON",
    Discount: 0.396,
  },
  {
    Vendor: "ARCTIC COOLING SYSTEM",
    Discount: 0.2,
  },
  {
    Vendor: "ATOSA",
    Discount: 0.6,
  },
  {
    Vendor: "AVANTCO / CLARK SERVICE GROUP",
    Discount: 0.2,
  },
  {
    Vendor: "BALLY",
    Discount: 0.5,
    PromptForListPrice: true,
  },
  {
    Vendor: "BASTIAN BLESSING / EMCO",
    Discount: 0.25,
    PromptForListPrice: true,
  },
  {
    Vendor: "BEVERAGE AIR",
    Discount: 0.55,
    PromptForListPrice: true,
  },
  {
    Vendor: "BEVERAGE AIR DROPSHIP",
    MinOrder: "$200",
    Discount: 0.55,
    PromptForListPrice: true,
  },
  {
    Vendor: "BLUE AIR",
    Discount: 0.73,
  },
  {
    Vendor: "BOHN / HEATCRAFT / THOMPSON SUPPY",
    Discount: 1 - 0.51063,
  },
  {
    Vendor: "COMMERCIAL REFRIGERATION COMPANY",
    Discount: 0.3,
  },
  {
    Vendor: "COMPONENT HARDWARE GROUP",
    Discount: 0.475,
  },
  {
    Vendor: "CONTINENTAL",
    Discount: 0.4,
  },
  {
    Vendor: "ELECTRO FREEZE / F.E.A. SERVICE",
    Discount: 0.3,
  },
  {
    Vendor: "ENTRÉE / HAGAR PARTS",
    Discount: 0.25,
  },
  {
    Vendor: "EVEREST",
    Discount: 0.65,
  },
  {
    Vendor: "FOGEL USA",
    MinOrder: "$20.00",
    Discount: 0.5,
  },
  {
    Vendor: "FOLLETT",
    Discount: 0.19,
  },
  {
    Vendor: "FOSTER",
    Discount: 0.25,
    PromptForListPrice: true,
  },
  {
    Vendor: "FRANK DOOR",
    MinOrder: "$25",
    Discount: 0.2,
  },
  {
    Vendor: "FRIGOGLAS / STAJAC / EXCELLENCE INDUSTRIES",
    // 10/23/24 Reduced discount from 35% to 20% per Shaina
    // https://caseparts.slack.com/archives/CQUC95QF9/p1729689673536539
    Discount: 0.2,
  },
  {
    Vendor: "GLASTENDER",
    Discount: 0.55,
    EndUserMarkup: 1 / 0.45,
    DealerMarkup: 0.75 / 0.45,
    WholesalerMarkup: 0.7 / 0.45,
    PromptForListPrice: true,
  },
  {
    Vendor: "GLOBAL / KELVINATOR",
    Discount: 0.5725,
    PromptForListPrice: true,
  },
  {
    Vendor: "GLOBAL / KELVINATOR DROPSHIPS",
    Discount: 0.5,
    PromptForListPrice: true,
  },
  {
    Vendor: "HK INTERNATIONAL",
    Discount: 0.5,
  },
  {
    Vendor: "HOBART",
    Discount: 0.5,
    PromptForListPrice: true,
  },
  {
    Vendor: "HOBART / DROP SHIPMENTS",
    Discount: 0.44,
    PromptForListPrice: true,
  },
  {
    Vendor: "HOSHIZAKI WESTERN",
    Discount: 0.15,
  },
  {
    Vendor: "HOWARD / McCRAY",
    Discount: 0.5,
  },
  {
    Vendor: "HTPG aka RUSSELL / WITT (FOR PARTS ORDERS ONLY)",
    MinOrder: "$50",
    Discount: 0.692,
    PromptForListPrice: true,
  },
  {
    Vendor: "HTPG aka RUSSELL / WITT (FOR EQUIPMENT ONLY)",
    MinOrder: "$50",
    Discount: 0.79,
    PromptForListPrice: true,
  },
  {
    Vendor: "HUSSMANN / OEM",
    Discount: 0.1,
  },
  {
    Vendor: "HUSSMANN/FAZIO",
    Discount: 0.19618,
  },
  {
    Vendor: "IKON / MVP GROUP",
    MinOrder: "$50",
    Discount: 0.3,
  },
  {
    Vendor: "INFRICO USA",
    Discount: 0.5,
    PromptForListPrice: true,
  },
  {
    Vendor: "INTERNATIONAL COLD STORAGE / EVERIDGE",
    Discount: 0,
  },
  {
    Vendor: "JAMISON DOOR COMPANY",
    Discount: 0.15,
  },
  {
    Vendor: "KAIRAK",
    Discount: 0.5,
    PromptForListPrice: true,
  },
  {
    Vendor: "KAIRAK / DROP SHIPMENTS",
    Discount: 0.44,
    PromptForListPrice: true,
  },
  {
    Vendor: "KASON",
    Discount: 0.5,
  },
  {
    Vendor: "KOLPAK",
    MinOrder: "$100 - and below min they add an extra $25",
    Discount: 0.5,
    PromptForListPrice: true,
  },
  {
    Vendor: "KRACK COIL / FAZIO",
    Discount: 0.3,
  },
  {
    Vendor: "KYSOR PANEL / KPS GLOBAL",
    Discount: 0.5,
  },
  {
    Vendor: "KYSOR / WARREN",
    Discount: 1 - 0.35715,
  },
  {
    Vendor: "LEER (Walk-Ins)",
    Discount: 0.0,
    PromptForListPrice: true,
  },
  {
    Vendor: "LEER (Ice Merch)",
    Discount: 0.25,
    PromptForListPrice: true,
  },
  {
    Vendor: "MARVEL aka AGA Marvel",
    Discount: 0.3,
  },
  {
    Vendor: "MICRO MATIC USA",
    Discount: 0.5,
  },
  {
    Vendor: "MIGALI",
    Discount: 0.5,
  },
  {
    Vendor: "MONTAGUE",
    Discount: 0.5,
    PromptForListPrice: true,
  },
  {
    Vendor: "NELSON aka C. NELSON",
    Discount: 0.55,
  },
  {
    Vendor: "NORRIS",
    Discount: 0.4,
  },
  {
    Vendor: "NORTHLAND",
    Discount: 0.4,
  },
  {
    Vendor: "OMNI TEMP / TEAM",
    Discount: 0.5,
  },
  {
    Vendor: "PEERLESS",
    MinOrder: "$100",
    Discount: 0.36,
  },
  {
    Vendor: "POWERS EQUIPMENT",
    Discount: 0.5,
  },
  {
    Vendor: "SILVER KING / MARMONLINK",
    Discount: 0.25,
    PromptForListPrice: true,
  },
  {
    Vendor: "SPARTAN (JMCFOODEQUIPMENT)",
    Discount: 0.2,
    PromptForListPrice: true,
  },
  {
    Vendor: "STYLELINE SYSTEMS / COMMERCIAL REFRIG",
    Discount: 0.4,
  },
  {
    Vendor: "THERMALRITE / EVERIDGE",
    Discount: 0,
  },
  {
    Vendor: "THERMO-KOOL",
    Discount: 0.5,
  },
  {
    Vendor: "TRAULSEN",
    Discount: 0.5,
    PromptForListPrice: true,
  },
  {
    Vendor: "TRAULSEN / DROP SHIPMENTS",
    Discount: 0.44,
    PromptForListPrice: true,
  },
  {
    Vendor: "TRUE MFG",
    Discount: 0.595,
  },
  {
    Vendor: "TURBO AIR",
    Discount: 0.7,
    PromptForListPrice: true,
  },
  {
    Vendor: "TURBO AIR CONDITIONING",
    Discount: 0.0,
    PromptForListPrice: true,
  },
  {
    Vendor: "U-LINE",
    Discount: 0.25,
  },
  {
    Vendor: "UTILITY",
    Discount: 0.5,
  },
  {
    Vendor: "VICTORY",
    Discount: 0.55,
    PromptForListPrice: true,
  },
  {
    Vendor: "ZERO ZONE",
    Discount: 0.495,
    EndUserMarkup: 1 / (1 - 0.495),
    DealerMarkup: 1.65,
    WholesalerMarkup: 1.6,
    PromptForListPrice: true,
  },
  {
    Vendor: ">>> Non-Standard Vendor",
    Discount: 0.0,
  },
];

export default vendors;
