import React from "react";

const Unauthorized = () => {
  return (
    <div className="auth">
      <h1>Unauthorized</h1>
      <p>
        Only Case Parts employees logged in at caseparts.com may access this
        page.
      </p>
      <p>
        <a href="https://mobile.caseparts.com/login?returnUrl=https://spo-pricing.caseparts.com">
          Login
        </a>
      </p>
    </div>
  );
};

export default Unauthorized;
