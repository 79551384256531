import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PartPricing from "./PartPricing";
import VendorMarkups from "./VendorMarkups";
import VendorMarkupsExport from "./VendorMarkupsExport";
import PartstownMarkups from "./PartstownMarkups";
import PricingTable from "./PricingTable";
import Invite from "../auth/Invite";
import Unauthorized from "../auth/Unauthorized";
import withAuth from "../auth/withAuth"; // Import your HOC
import "../styles/styles.css";
import "../styles/auth.css";

// Wrap components with withAuth
const ProtectedPartPricing = withAuth(PartPricing);
const ProtectedVendorMarkups = withAuth(VendorMarkups);
const ProtectedVendorMarkupsExport = withAuth(VendorMarkupsExport);
const ProtectedPartstownMarkups = withAuth(PartstownMarkups);
const ProtectedPricingTable = withAuth(PricingTable);

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/invite" element={<Invite />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/partstown" element={<ProtectedPartstownMarkups />} />
          <Route path="/vendors" element={<ProtectedVendorMarkups />} />
          <Route path="/export" element={<ProtectedVendorMarkupsExport />} />
          <Route path="/pricing" element={<ProtectedPricingTable />} />
          <Route path="/" element={<ProtectedPartPricing />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
