import React, { useRef, useState, useEffect } from "react";
import "../styles/DrawerStyles.css";

// can this component be refactored to a more declarative CSS-based approach
// rather than measuring content to set max-heights to ensure smooth animation?
export default function Drawer({ isOpen, setIsOpen, maxHeight, children }) {
  const contentRef = useRef();
  const parentRef = useRef();
  const [contentHeight, setContentHeight] = useState(maxHeight ?? 400);

  // retrieve children height for smooth animation
  useEffect(() => {
    if (!isOpen) return;

    // store current content max-height - to restore later
    const prevHeight = contentRef.current.style.maxHeight;

    // allows us to measure children element height
    contentRef.current.style.maxHeight = "none";

    // hide children elements as drawer is opening
    parentRef.current.style.overflow = "hidden";

    const height = contentRef.current.getBoundingClientRect().height;
    setContentHeight(height > 0 ? height : contentHeight);

    // restore max-height after measuring
    contentRef.current.style.maxHeight = prevHeight;

    // hack: wait transition duration to allow handle to overlay parent
    const timeoutId = setTimeout(() => {
      parentRef.current.style.overflow = "visible";
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen, children]);

  const parentStyles = {
    maxHeight: isOpen ? `${contentHeight + 48}px` : "10px",
    border: isOpen ? "1px solid #dfdfdf" : "1px solid white",
    padding: isOpen ? "16px 16px 32px 16px" : "16px 16px 0px 16px",
    marginBottom: isOpen ? "16px" : "0px",
  };

  const contentStyles = {
    maxHeight: isOpen ? `${contentHeight}px` : "0px",
  };

  const handleStyles = {
    borderBottom: isOpen ? "1px solid white" : "1px solid #dfdfdf",
  };

  const pre = (c) => "drawer__" + c;

  return (
    <div ref={parentRef} style={parentStyles} className={pre("main")}>
      <div ref={contentRef} style={contentStyles} className={pre("content")}>
        {children}
      </div>
      <div
        style={handleStyles}
        className={pre("handle")}
        onClick={() => setIsOpen(!isOpen)}
      >
        <button onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? "Hide Details" : "Show Details"}
        </button>
      </div>
    </div>
  );
}
