import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useMarkups from "../components/useMarkups";
import vendorMarkups from "../data/vendorMarkups";
import partstownMarkups from "../data/partstownMarkups";
import ReturnPrevious from "../components/ReturnPrevious";
import MarkupChart from "../components/MarkupChart";
import "../styles/ReviewMarkups.css";

const pre = (c) => "review-markups__" + c;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PricingTable = () => {
  const [increment, setIncrement] = useState(10);
  const RefPrice = 100;

  // Check if the 'pt' query parameter is present
  const query = useQuery();
  const isPartstown = query.get("pt") !== null;
  const [showPartstown, setShowPartstown] = useState(isPartstown);

  // Choose the markup data based on the 'pt' query parameter
  const markupData = showPartstown ? partstownMarkups : vendorMarkups;

  const {
    calcEndUserMarkup,
    calcDealerMarkup,
    calcWholesalerMarkup,
    setMarkupData,
  } = useMarkups(markupData);

  const handleIsPartstown = (isPartstown) => {
    setShowPartstown(isPartstown);
    setMarkupData(isPartstown ? partstownMarkups : vendorMarkups);
  };

  const ReviewMarkupsLink = ({ to, label }) => {
    if (!label) label = "Review Markups";
    return (
      <Link className="review-markups-link" to={to}>
        {label}
      </Link>
    );
  };

  // Calculate the number of discount steps based on the selected increment
  const Count = Math.floor(90 / increment) + 1;

  // Generate the discounts array based on the selected increment
  const discounts = Array.from(
    { length: Count },
    (_, i) => (i * increment) / 100
  );

  return (
    <div className={pre("container")}>
      <div className="alert">
        <strong>ATTENTION:</strong>&ensp;Case Parts internal use only!
      </div>
      <h1>
        {showPartstown ? "Parts Town" : "Vendor"} Markups and Prices by Discount{" "}
        <ReturnPrevious />
        <ReviewMarkupsLink
          to={showPartstown ? "/partstown" : "/vendors"}
          label={showPartstown ? "PT Markups" : "Markups"}
        />
      </h1>
      <p>
        <i>Prices reflect purchase of an item with a ${RefPrice} MSRP</i>
      </p>
      <MarkupChart
        markups={markupData} /*cutoff={showPartstown ? 0.5 : null}*/
      />
      <div className={pre("checkbox")}>
        <label>
          <input
            type="checkbox"
            checked={showPartstown}
            onChange={(e) => handleIsPartstown(e.target.checked)}
          />{" "}
          Show Parts Town price curve
        </label>
      </div>
      {/* Increment Select Box */}
      <div>
        <label>Increment: </label>
        <select
          value={increment}
          onChange={(e) => setIncrement(Number(e.target.value))}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="5">5</option>
          <option value="10">10</option>
        </select>
      </div>
      <div className={pre("container pricing")}>
        {/* Header Row */}
        <div className={pre("header")}>
          <div>Discount</div>
          <div>Cost</div>
          <div>
            End&nbsp;User
            <br />
            Markup
          </div>
          <div>
            Dealer
            <br />
            Markup
          </div>
          <div>
            Wholesaler
            <br />
            Markup
          </div>
          <div>
            End&nbsp;User
            <br />
            Price
          </div>
          <div>
            Dealer
            <br />
            Price
          </div>
          <div>
            Wholesaler
            <br />
            Price
          </div>
        </div>
        {/* Discount Rows */}
        {discounts.map((discount, index) => {
          const cost = (1 - discount) * RefPrice;
          const endUserPrice = (cost * calcEndUserMarkup(discount)).toFixed(2);
          const dealerPrice = (cost * calcDealerMarkup(discount)).toFixed(2);
          const wholesalerPrice = (
            cost * calcWholesalerMarkup(discount)
          ).toFixed(2);

          return (
            <React.Fragment key={index}>
              <div className={pre("row")}>
                <div>{(discount * 100).toFixed(0)}%</div>
                <div>${cost.toFixed(2)}</div>
                <div>{calcEndUserMarkup(discount).toFixed(2)}</div>
                <div>{calcDealerMarkup(discount).toFixed(2)}</div>
                <div>{calcWholesalerMarkup(discount).toFixed(2)}</div>
                <div className={endUserPrice > 100 ? pre("over100") : ""}>
                  ${endUserPrice}
                </div>
                <div className={dealerPrice > 100 ? pre("over100") : ""}>
                  ${dealerPrice}
                </div>
                <div className={wholesalerPrice > 100 ? pre("over100") : ""}>
                  ${wholesalerPrice}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default PricingTable;
